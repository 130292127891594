var datatable;

$(function () {

  datatable = $("#dataTable").DataTable({
    "pageLength": 100,
    "order": [],
    "language": {
      "lengthMenu": "Vedi _MENU_ record per pagina",
      "zeroRecords": "Nessun record trovato",
      "info": "Pagina _PAGE_ di _PAGES_",
      "infoEmpty": "Nessun record disponibile",
      "infoFiltered": "(trovati da _MAX_ records)",
      "search": "Cerca:",
      "paginate": {
        "first": "Prima",
        "last": "Ultima",
        "next": "Prossimo",
        "previous": "Precedente"
      },
      "loadingRecords": "Caricamento...",
    }
  });

  $(".filter-tab").on('click', function (e) {
    e.preventDefault();
    var val = $(this).data('val');
    datatable.column(1).search( val ).draw();
    $(".filter-tab").parent().removeClass('text-blue-500 border-blue-500');
    $(this).parent().addClass('text-blue-500 border-blue-500');
  });

  $(".sticky").sticky({ topSpacing: 94 });


  $("#add_umbrellas").on('click', function () {
    $("#form_umbrellas").toggleClass('invisible');
  })

  $("#select_filter").on('click', function () {
    user_on_map();
    $("#form_filter").toggleClass('invisible');
  })

  /*Toggle dropdown list*/
  /*https://gist.github.com/slavapas/593e8e50cf4cc16ac972afcbad4f70c8*/

  var userMenuDiv = document.getElementById("userMenu");
  var userMenu = document.getElementById("userButton");

  var navMenuDiv = document.getElementById("nav-content");
  var navMenu = document.getElementById("nav-toggle");

  if (userMenuDiv)
    document.onclick = check;

  function check(e) {
    var target = (e && e.target) || (event && event.srcElement);

    //User Menu
    if (!checkParent(target, userMenuDiv)) {
      // click NOT on the menu
      if (checkParent(target, userMenu)) {
        // click on the link
        if (userMenuDiv.classList.contains("invisible")) {
          userMenuDiv.classList.remove("invisible");
        } else { userMenuDiv.classList.add("invisible"); }
      } else {
        // click both outside link and outside menu, hide menu
        userMenuDiv.classList.add("invisible");
      }
    }

    //Nav Menu
    if (!checkParent(target, navMenuDiv)) {
      // click NOT on the menu
      if (checkParent(target, navMenu)) {
        // click on the link
        if (navMenuDiv.classList.contains("hidden")) {
          navMenuDiv.classList.remove("hidden");
        } else { navMenuDiv.classList.add("hidden"); }
      } else {
        // click both outside link and outside menu, hide menu
        navMenuDiv.classList.add("hidden");
      }
    }

  }

  function check_form(e) {
    var target = (e && e.target) || (event && event.srcElement);

    //User Menu
    if (!checkParent(target, umbrellaMenuDiv)) {
      // click NOT on the menu
      if (checkParent(target, umbrellaMenu)) {
        // click on the link
        if (umbrellaMenuDiv.classList.contains("invisible")) {
          umbrellaMenuDiv.classList.remove("invisible");
        } else { umbrellaMenuDiv.classList.add("invisible"); }
      } else {
        // click both outside link and outside menu, hide menu
        umbrellaMenuDiv.classList.add("invisible");
      }
    }

    //Nav Menu
    if (!checkParent(target, umbrellaMenuDiv)) {
      // click NOT on the menu
      if (checkParent(target, umbrellaMenu)) {
        // click on the link
        if (umbrellaMenuDiv.classList.contains("hidden")) {
          umbrellaMenuDiv.classList.remove("hidden");
        } else { umbrellaMenuDiv.classList.add("hidden"); }
      } else {
        // click both outside link and outside menu, hide menu
        umbrellaMenuDiv.classList.add("hidden");
      }
    }

  }

  function checkParent(t, elm) {
    while (t.parentNode) {
      if (t == elm) { return true; }
      t = t.parentNode;
    }
    return false;
  }
})


